import React, { useEffect, useState } from 'react'
import Modal from 'components/ui/Modal'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { headerBG } from './icons'
import { upgradePlanURL } from 'utils/urls'

const ModalContainer = styled.div`
    .modal-dialog.mbasic .modal-content {
        padding: 0;
        border-radius: 9px;
    }
`

const HeaderContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.yellowdk2};
    background-image: url(${headerBG});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 50px 20px;
    border-top-right-radius: 9px;
    border-top-left-radius: 9px;
`

const Header = styled.h2`
    margin: 0;
    font-size: 36px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.white};
`

const TextContainer = styled.div`
    padding: 37px 40px 44px;
    text-align: center;
`

const Text = styled.p`
    font-size: 20px;
`

const SubHeader = styled.h4`
    text-transform: uppercase;
    font-weight: 800;
    font-size: 16px;
    letter-spacing: 1.75px;
    margin: 33px 0;
`

const FeatureList = styled.ul`
    list-style: none;
    margin: 0 0 33px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`

const FeatureListItem = styled.li`
    display: flex;
    flex-direction: column;
    padding: 12px 42px;
    background-color: ${({ theme }) => theme.colors.ltblue2};
    max-width: 190px;
    font-weight: 600;
    line-height: 22px;

    @media (max-width: 768px) {
        padding: 10px 15px;
        max-width: 145px;
    }
`

const Icon = styled.i`
    margin-bottom: 6px;
`

const PlansLink = styled.a`
    margin-right: 8px;
`

interface AppState {
    current_user: {
        trial_days_remaining: number
        trial_days_remaining_integer: number
    }
    plan_status: string
}

export const SHOW_MODAL_KEY = 'show-trial-expiration-modal'

export const TrialExpirationCountdown = () => {
    const { trialDaysRemaining, planStatus, trialDaysRemainingInteger } =
        useSelector(
            ({
                app: {
                    current_user: {
                        trial_days_remaining,
                        trial_days_remaining_integer,
                    },
                    plan_status,
                },
            }: {
                app: AppState
            }) => ({
                trialDaysRemaining: trial_days_remaining,
                planStatus: plan_status,
                trialDaysRemainingInteger: trial_days_remaining_integer,
            })
        )

    const isOnTrial = planStatus === 'Trial'

    // Only show modal if trial will expire in <= 3 days
    const [showExpirationModal, setShowExpirationModal] = useState(
        isOnTrial && trialDaysRemainingInteger <= 3
    )

    // Retrieve initial modal state from localStorage
    const initialModalState = localStorage.getItem(SHOW_MODAL_KEY)

    useEffect(() => {
        if (
            isOnTrial &&
            trialDaysRemainingInteger <= 3 &&
            initialModalState !== 'false'
        ) {
            localStorage.setItem(SHOW_MODAL_KEY, 'true')
            setShowExpirationModal(true)
        }
    }, [])

    const closeModal = () => {
        localStorage.setItem(SHOW_MODAL_KEY, 'false')
        setShowExpirationModal(false)
    }

    return (
        <>
            {showExpirationModal && initialModalState === 'true' ? (
                <ModalContainer>
                    <Modal onClose={closeModal}>
                        <HeaderContainer>
                            <Header>
                                Your trial is set to expire in{' '}
                                {trialDaysRemaining}!
                            </Header>
                        </HeaderContainer>

                        <TextContainer>
                            <Text>
                                Speak with one of our shipping experts to learn
                                how ShipperHQ can work to support your shipping
                                strategy and business goals.
                            </Text>

                            <SubHeader>
                                Standard plans and above include:
                            </SubHeader>

                            <FeatureList>
                                <FeatureListItem>
                                    <Icon className="fas fa-user-headset"></Icon>
                                    Priority support
                                </FeatureListItem>
                                <FeatureListItem>
                                    <Icon className="fas fa-wrench"></Icon>
                                    Configuration Assistance
                                </FeatureListItem>
                                <FeatureListItem>
                                    <Icon className="fas fa-grid-2-plus"></Icon>
                                    Access to all core features
                                </FeatureListItem>
                            </FeatureList>

                            <PlansLink
                                className="btn btn-primary"
                                href={upgradePlanURL()}
                                onClick={closeModal}
                            >
                                See Plans
                            </PlansLink>

                            <a
                                href="https://meetings.hubspot.com/quentin-montalto/round-robin-shared-sales-calendar"
                                target="_blank"
                                rel="noopener"
                                className="btn btn-bl-o"
                                onClick={closeModal}
                            >
                                Speak to an expert
                            </a>
                        </TextContainer>
                    </Modal>
                </ModalContainer>
            ) : null}
        </>
    )
}
