import { useEffect } from 'react'
import { axiosClient } from 'net/ajax'
import { BroadcastChannel } from 'broadcast-channel'
import { SHOW_MODAL_KEY } from 'components/dashboard/home/components/TrialExpirationCountdown'
import { STORE_USER_ID } from 'utils/userIdMonitor'

const LOGOUT_EVENT = 'LOGOUT'

const logoutChannel = new BroadcastChannel('logout')

export const authBroadcastEvents = {
    logout: () => logoutChannel.postMessage({ type: LOGOUT_EVENT }),
}

export const useAuthBroadcastObserver = () => {
    useEffect(() => {
        if (window.document.getElementById('cypress-enabled')) return

        logoutChannel.onmessage = (data) => {
            switch (data.type) {
                case LOGOUT_EVENT:
                    window.location = '/'
                    break
                default:
                    break
            }
        }

        return () => {
            logoutChannel.close()
        }
    }, [])
}

export const onLogout = (event) => {
    event.preventDefault()

    axiosClient
        .request({
            url: '/user/sign_out',
            method: 'DELETE',
        })
        .then((result) => {
            if (result.status === 200 && result.data) {
                authBroadcastEvents.logout()
                window.location.href = '/'

                localStorage.removeItem(STORE_USER_ID)

                if (localStorage.getItem(SHOW_MODAL_KEY)) {
                    localStorage.removeItem(SHOW_MODAL_KEY)
                }

                window.location.href = '/'
            }
        })
        .catch((error) => {})

    return false
}
