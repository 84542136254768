export const STORE_USER_ID = 'shq-user-id'
const MONITOR_INTERVAL = 1000

/**
 * This function monitors user id from the current session
 * and redirects to the index page when user id changes
 */
export function monitorUserId() {
    const currentUserId = window?.appData?.current_user?.id

    const intervalId = setInterval(() => {
        const currentId = localStorage.getItem(STORE_USER_ID)
        if (!currentId) {
            return
        }

        // check if current user id has changed
        if (currentId.toString() !== currentUserId.toString()) {
            clearInterval(intervalId)
            // if it did - redirect to the index page
            window.open(window?.Routes?.ratesmgr_dashboards_path(), '_self')
        }
    }, MONITOR_INTERVAL)
}

/**
 * Updates user id in the storage
 */
export function updateUserId() {
    localStorage.setItem(STORE_USER_ID, window?.appData?.current_user?.id)
}
